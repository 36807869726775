



















































































































































































































/* eslint-disable */
import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import VuexyLogo from '@/vuexy/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@/vuexy/utils/validations/validations'
import { togglePasswordFunc } from '@/vuexy/mixins/ui/forms'
import { store } from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@/vuexy/components/toastification/ToastificationContent.vue'

const sideImg = require('@/assets/images/pages/login-v2.svg')
const sideImgDark = require('@/assets/images/pages/login-v2-dark.svg')

export default Vue.extend({
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      status: '',
      password: 'admin',
      userEmail: 'admin@demo.com',

      // validation rules
      required,
      email,
    }
  },
  methods: {
    login() {
      const loginForm: any = this.$refs.loginForm

      loginForm.validate().then((success: boolean) => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              const { userData } = response.data
              useJwt.setToken(response.data.accessToken)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  const toast: any = {
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Welcome ${userData.fullName || userData.username}`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                    },
                  }

                  this.$toast(toast)
                })
            })
            .catch(error => {
              loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
  setup() {
    const {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    } = togglePasswordFunc()

    const imgUrl = computed(() =>  {
      if (store.state.appConfig.layout.skin === 'dark') {
        return sideImgDark
      }

      return sideImg
    })

    return {
      imgUrl,
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    }
  },
})
